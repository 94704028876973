import React from 'react';
import { web3Modal } from '../ethereum/web3';
import Web3 from 'web3';

import { Card, CardHeader, CardTitle, CardBody, Col, Row, Container, Button } from 'reactstrap';
import { GasPriceOracle } from 'gas-price-oracle';
import SwapCard from '../components/SwapCard';
import { getGswpstkContract } from '../ethereum/gswpstk';
import { getGswpContract } from '../ethereum/gswp';
import { getGasSwapContract } from '../ethereum/gasSwap';
import axios from 'axios';
import SwapConfirm from '../components/SwapConfirm';
import { API_URL } from 'config';
export default class Main extends React.Component {
  state = {
    gswpstkBalance: '',
    gswpBalance: '',
    gasPrice: 0,
    exchangeRate: 0,
    swapLoading: false,
    account: undefined,
    chainId: 0,
    web3: null
  }

  async componentDidMount() {
    console.log('web 3 modal = ', web3Modal);
    if (web3Modal.cachedProvider) {
      this.connectWallet();
    }

    this.getGasPrice();
    setInterval(() => this.getGasPrice(), 30000);
  }

  async connectWallet() {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    this.subscribeToProvider(provider);
    this.setState({ web3 })
    const accounts = await this.state.web3.eth.getAccounts();
    this.setState({ 
      account: accounts[0],
      chainId: provider.chainId
    }, () => {
      if (provider.chainId === '0x4' || provider.chainId === 4 || provider.chainId === '4') {
        this.getBalances();
      } else {
        this.setState({
          gswpstkBalance: '',
          gswpBalance: '',
        })
      }  
    });
  }

  subscribeToProvider(provider) {
    provider.on("accountsChanged", (accounts) => {
      this.setState({ 
        account: accounts[0],
      }, () => {
        this.getBalances();
      });  
    });

    provider.on("chainChanged", (chainId) => {
      this.setState({ chainId }, () => {
        if (chainId === '0x4' || chainId === 4 || provider.chainId === '4') {
          this.getBalances();
        } else {
          this.setState({
            gswpstkBalance: '',
            gswpBalance: '',
          })
        }
      });
    });

    provider.on("connect", (info) => {
      console.log('connect = ', info);
      this.connectWallet();
    });

    provider.on("disconnect", (error) => {
      console.log('disconnect = ', error);
    });
  }

  async getGasPrice() {
    try {
      const oracle = new GasPriceOracle();
      const prices = await oracle.fetchGasPricesOffChain();
      const gasPrice = prices.standard;
      const exchangeRate = gasPrice * 10;
      this.setState({
        gasPrice: parseInt(gasPrice),
        exchangeRate
      });
    } catch (err) {
      console.log('gas err = ', err);
    }
  }

  async getBalances() {
    if (this.state.web3) {
      const accounts = await this.state.web3.eth.getAccounts();
      console.log('accounts == ', this.state)
  
      if (accounts.length > 0) {
        const { chainId, web3 } = this.state;
        const gswpBalance = await getGswpContract(chainId, web3).methods.balanceOf(accounts[0]).call();
        const gswpstkBalance = await getGswpstkContract(chainId, web3).methods.balanceOf(accounts[0]).call();
        this.setState({
          account: accounts[0],
          gswpstkBalance,
          gswpBalance
        })
      } else {
        this.setState({
          // account: undefined,
          gswpstkBalance: '',
          gswpBalance: '',
        })
      }
    } else {
      this.setState({
        // account: undefined,
        gswpstkBalance: '',
        gswpBalance: '',
      })
    }
  }

  async getStakeQuote(values) {
    const { gswpIn } = values;
    this.setState({ swapLoading: true });
    const accounts = await this.state.web3.eth.getAccounts();
    const url = API_URL + 'getStakeQuote?address=' + accounts[0] + '&gswpIn=' + gswpIn;
    const response = await axios.get(url);
    this.setState({
      quote: { ...response.data, swapType: 1 },
      swapLoading: false
    })
  }

  async getClaimQuote(values) {
    const { gswpstkIn } = values;
    this.setState({ swapLoading: true });
    const accounts = await this.state.web3.eth.getAccounts();
    const url = API_URL + 'getClaimQuote?address=' + accounts[0] + '&gswpstkIn=' + gswpstkIn;
    const response = await axios.get(url);
    this.setState({
      quote: { ...response.data, swapType: 2 },
      swapLoading: false
    })
  }

  async stakeGSWP(values) {
    console.log('ggc val == ', values);
    try {
      const { chainId, web3 } = this.state;
      const { signature, gasPrice, gswpIn, gswpstkOut, expires } = values;
      const accounts = await this.state.web3.eth.getAccounts();
      this.setState({ swapLoading: true });
      await getGasSwapContract(chainId, web3).methods.stakeGSWP(signature, gasPrice, gswpIn, gswpstkOut, expires).send({
        from: accounts[0]
      });
      this.setState({ swapLoading: false });
      this.getBalances();
    } catch (err) {
      this.setState({ swapLoading: false });
    }
  }

  async claimGSWP(values) {
    console.log('ggt val == ', values);
    try {
      const { chainId, web3 } = this.state;
      const { signature, gasPrice, gswpstkIn, gswpOut, expires } = values;
      const accounts = await this.state.web3.eth.getAccounts();
      this.setState({ swapLoading: true });
      await getGasSwapContract(chainId, web3).methods.claimGSWP(signature, gasPrice, gswpstkIn, gswpOut, expires).send({
        from: accounts[0]
      });
      this.setState({ swapLoading: false });
      this.getBalances();
    } catch (err) {
      this.setState({ swapLoading: false });
    }
  }

  onCloseClaim() {
    this.setState({
      quote: undefined,
      quoteLoading: false
    })
  }

  onConfirmSwap() {
    console.log('confirming swap = ', this.state.quote);
    this.onCloseClaim();
    this.setState({ swapLoading: true });
    if (this.state.quote.swapType === 1) {
      this.stakeGSWP(this.state.quote);
    } else {
      this.claimGSWP(this.state.quote);
    }
  }

  renderChainAlert() {
    return (
      <Card className="card-swap" style={{ width: '100%' }}>
        <CardHeader>
        </CardHeader>
        <CardBody style={{ textAlign: 'center' }}>
          <h1 className="text-flame text-head">
            Invalid Chain<br></br>
          </h1>
          <p className="text-earth text-hl">Connect to the Ethereum Rinkeby TestNet to continue</p>
        </CardBody>
      </Card>
    )
  }

  renderWalletButton() {
    return (
      <Card className="card-swap" style={{ width: '100%' }}>
        <CardHeader>
        </CardHeader>
        <CardBody>
          <h1 className="text-earth text-sub" style={{ textAlign: 'center' }}>
            Connect your wallet to get started!
          </h1>
          <Button
            onClick={() => this.connectWallet()}
            block
            className="btn-simple btn-round btn-flame"
            color="white"
            size="lg"
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <i className="fa fa-wallet text-flame" style={{ marginRight: '12px', fontSize: '28px' }} />
              <div className="text-hl text-flame">CONNECT</div>
            </div>
          </Button>

        </CardBody>
      </Card>
    )
  }

  renderSwapCard() {
    const {
      gswpstkBalance,
      gswpBalance,
      gasPrice,
      exchangeRate,
      swapLoading,
      quoteLoading,
      quote,
      account,
      web3
    } = this.state;
    return (
      <SwapCard
        gswpstkBalance={gswpstkBalance}
        gswpBalance={gswpBalance}
        gasPrice={gasPrice}
        exchangeRate={exchangeRate}
        onPurchaseGSWPSTK={(values) => this.getStakeQuote(values)}
        onClaimGSWP={(values) => this.getClaimQuote(values)}
        swapLoading={swapLoading}
        web3={web3}
      />
    )
  }

  render() {
    const {
      gswpstkBalance,
      gswpBalance,
      gasPrice,
      exchangeRate,
      swapLoading,
      quoteLoading,
      quote,
      account,
      web3,
      chainId
    } = this.state;

    return (
      <div>
        {quoteLoading || quote &&
          <SwapConfirm
            loading={quoteLoading}
            quote={quote}
            toggleModalOpen={() => this.onCloseClaim()}
            onConfirmSwap={() => this.onConfirmSwap()}
            web3={web3}
          />
        }
        <div className="page-header">
          <Container>
            {/* <div className="text-earth text-hl">{chainId}</div> */}
            {!account ?
              <div>
                {this.renderWalletButton()}
              </div>
              :
              <div>
                {chainId !== '0x4' && chainId !== 4  && chainId !== '4' ?
                  <div>
                    {this.renderChainAlert()}
                  </div>
                  :
                  <div>
                    {this.renderSwapCard()}
                  </div>
                }
              </div>
            }
          </Container>
        </div>
      </div>
    )
  }
}
