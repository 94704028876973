export const GAS_SWAP_ADDRESS_MAIN = "0x929eFd721E7EdD1c65732Ed71d3fc20Bf2738FBD";
export const GAS_SWAP_ADDRESS_RINKEBY = "0x929eFd721E7EdD1c65732Ed71d3fc20Bf2738FBD";

export const GSWP_ADDRESS_MAIN = "0x1b52f1123F8fd53e0731402B546edb29AfBD58b1";
export const GSWP_ADDRESS_RINKEBY = "0x1b52f1123F8fd53e0731402B546edb29AfBD58b1";

export const GSWPSTK_ADDRESS_MAIN = "0x99C632167E4E8E63d9ec08836379A96234d74548";
export const GSWPSTK_ADDRESS_RINKEBY = "0x99C632167E4E8E63d9ec08836379A96234d74548";

export const UNISWAP_MAIN_URL = "https://app.uniswap.org/#/swap?outputCurrency=0x1b52f1123F8fd53e0731402B546edb29AfBD58b1";
export const UNISWAP_RINKEBY_URL = "https://app.uniswap.org/#/swap?outputCurrency=0x1b52f1123F8fd53e0731402B546edb29AfBD58b1";

// export const API_URL = 'http://localhost:3000/dev/'
export const API_URL = 'https://api.gasswap.io/';