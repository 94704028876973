/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.2.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

import App from '../src/pages/App';
import ColorNavbar from 'components/Navbars/ColorNavbar';
import DemoFooter from 'components/Footers/DemoFooter';

ReactDOM.render(
  <BrowserRouter>
        <ColorNavbar />
    <Switch>
      <Route path="/" render={(props) => <App {...props} />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
