/* global BigInt */

import { Card, CardBody, CardHeader, Col, Row, CardTitle, CardFooter, Input, Nav, NavItem, NavLink, Button, Badge } from 'reactstrap';
import Countdown from "react-countdown";
// import this.props.web3 from '../ethereum/this.props.web3';
import React from 'react';
import classnames from "classnames";
import Loader from "react-loader-spinner";
import { UNISWAP_RINKEBY_URL } from 'config'
//swaptype 1 = GSWP - GSWPSTK
//swaptype 2 = GSWPSTK - GSWP

export default class SwapCard extends React.Component {
    state = {
        swapType: 1,
        gswpError: '',
        gswpstkError: '',
        gswpAmount: '0',
        gswpstkAmount: '0'
    }

    toggleSwapType() {
        this.setState({
            swapType: this.state.swapType === 1 ? 2 : 1,
            gswpstkAmount: '0',
            gswpAmount: '0'
        }, () => this.validateBalances())
    }

    onGswpMaxClick() {
        var gswpAmount = BigInt(this.props.gswpBalance);
        var gswpstkAmount = gswpAmount / BigInt(this.props.exchangeRate);
        this.setState({
            gswpAmount: this.props.web3.utils.fromWei(gswpAmount.toString(), 'ether'),
            gswpstkAmount: this.props.web3.utils.fromWei(gswpstkAmount.toString(), 'ether'),
        }, () => this.validateBalances())
    }

    onGswpstkMaxClick() {
        var gswpstkAmount = BigInt(this.props.gswpstkBalance);
        var gswpAmount = gswpstkAmount * BigInt(this.props.exchangeRate);
        this.setState({
            gswpAmount: this.props.web3.utils.fromWei(gswpAmount.toString(), 'ether'),
            gswpstkAmount: this.props.web3.utils.fromWei(gswpstkAmount.toString(), 'ether'),
        }, () => this.validateBalances())
    }

    onGswpValueChange(value) {
        if (value) {
            var gswpAmount = BigInt(this.props.web3.utils.toWei(value));
            var gswpstkAmount = gswpAmount / BigInt(this.props.exchangeRate);
            this.setState({
                gswpAmount: this.props.web3.utils.fromWei(gswpAmount.toString(), 'ether'),
                gswpstkAmount: this.props.web3.utils.fromWei(gswpstkAmount.toString(), 'ether'),
            }, () => this.validateBalances())
        } else {
            this.setState({
                gswpAmount: '0',
                gswpstkAmount: '0',
            }, () => this.validateBalances())
        }
    }

    onGswpstkValueChange(value) {
        if (value) {
            var gswpstkAmount = BigInt(this.props.web3.utils.toWei(value));
            var gswpAmount = gswpstkAmount * BigInt(this.props.exchangeRate);
            this.setState({
                gswpstkAmount: this.props.web3.utils.fromWei(gswpstkAmount.toString(), 'ether'),
                gswpAmount: this.props.web3.utils.fromWei(gswpAmount.toString(), 'ether'),
            }, () => this.validateBalances())
        } else {
            this.setState({
                gswpAmount: '0',
                gswpstkAmount: '0',
            }, () => this.validateBalances())
        }
    }

    onSwapClick() {
        if (this.state.swapType === 1) {
            console.log('sate == ', this.state)
            this.props.onPurchaseGSWPSTK({
                gasPrice: this.props.gasPrice,
                gswpIn: this.props.web3.utils.toWei(this.state.gswpAmount, 'ether'),
                gswpstkOut: this.props.web3.utils.toWei(this.state.gswpstkAmount, 'ether'),
                expires: 98798798798
            });
        } else {
            this.props.onClaimGSWP({
                gasPrice: this.props.gasPrice,
                gswpOut: this.props.web3.utils.toWei(this.state.gswpAmount, 'ether'),
                gswpstkIn: this.props.web3.utils.toWei(this.state.gswpstkAmount, 'ether'),
                expires: 98798798798
            });
        }
    }

    validateBalances() {
        console.log('staet == ', this.state);
        this.setState({
            gswpError: this.state.swapType === 2 ? '' : BigInt(this.props.web3.utils.toWei(this.state.gswpAmount)) > BigInt(this.props.gswpBalance) ? 'Insufficient GSWP Balance' : '',
            gswpstkError: this.state.swapType === 1 ? '' : BigInt(this.props.web3.utils.toWei(this.state.gswpstkAmount)) > BigInt(this.props.gswpstkBalance) ? 'Insufficient GSWPSTK Balance' : '',
        })
    }

    renderInsufficientBalance() {
        return (
            <div style={{ textAlign: 'center' }}>
                <h2 className="text-earth text-sub">Insufficient GSWP Balance</h2>
                <p className="text-hl text-earth">
                    You can get GSWP from UniSwap
                </p>
                <Button
                    href={UNISWAP_RINKEBY_URL}
                    target="_blank"                                
                    block
                    className="btn-simple btn-round btn-flame"
                    color="white"
                    size="lg"
                    style={{maxHeight: '54px !important'}}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img style={{ width: '30px', maxHeight: '30px', marginRight: '20px' }} src={require("assets/uni_flame.png").default} />
                        <div className="text-hl text-flame">GSWP ON UNISWAP</div>
                    </div>
                </Button>
            </div>
        )
    }

    renderSwapContent() {
        const { swapType, gswpstkAmount, gswpAmount, gswpError, gswpstkError } = this.state;
        const {
            gswpstkBalance,
            gswpBalance,
            gasPrice,
            exchangeRate,
        } = this.props;
        return (
            <div style={{ display: 'flex', flexDirection: swapType === 1 ? 'column' : 'column-reverse' }}>

                <div style={{ background: '#2d1a0f', padding: '0.5rem', borderRadius: '1rem', marginBottom: '6px', padding: '1rem' }}>
                    <Row>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}>
                            <div className="text-warm">{swapType === 1 ? 'From' : 'To'}</div>
                            <div style={{ color: 'white' }}>Balance: {parseFloat(this.props.web3.utils.fromWei(gswpBalance, 'ether')).toFixed(3)}</div>
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Input
                                    // disabled={swapType === 2}
                                    pattern="[0-9]\d*$"
                                    style={{ fontSize: '20px', marginTop: '1rem', flex: 2 }}
                                    placeholder="0.0"
                                    value={gswpAmount}
                                    onChange={(e) => this.onGswpValueChange(e.target.value)}
                                />
                                {swapType === 1 &&
                                    <div style={{ alignSelf: 'flex-end', marginLeft: '2rem' }}>
                                        <Button onClick={() => this.onGswpMaxClick()} className="btn btn-simple btn-sm btn-flame">MAX</Button>
                                    </div>
                                }
                            </div>

                        </Col>
                        <Col sm="12" lg="3" style={{ margin: 'auto', textAlign: 'end', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                            <h3 style={{ fontSize: '20px', marginLeft: '2rem', display: 'flex', alignSelf: 'flex-end', marginBottom: 0, marginTop: '20px', }}>GSWP</h3>
                        </Col>
                    </Row>
                    {gswpError && <Badge color="danger" className="ml-1">{gswpError}</Badge>}

                </div>

                <div className="icon-big icon-info text-center" style={{ display: 'flex', fontSize: '1.5rem', width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '6px' }}>
                    <i className="fa fa-arrow-down text-flame" onClick={() => this.toggleSwapType()} style={{ cursor: 'pointer' }} />
                </div>

                <div style={{ background: '#2d1a0f', padding: '0.5rem', borderRadius: '1rem', marginBottom: '1rem', padding: '1rem' }}>
                    <Row>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}>
                            <div className="text-warm">{swapType === 2 ? 'From' : 'To'}</div>
                            <div style={{ color: 'white' }}>Balance: {parseFloat(this.props.web3.utils.fromWei(gswpstkBalance, 'ether')).toFixed(3)}</div>
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Input
                                    // disabled={swapType === 1}
                                    pattern="[0-9]\d*$"
                                    style={{ fontSize: '20px', marginTop: '1rem', flex: 2 }}
                                    placeholder="0.0"
                                    value={gswpstkAmount}
                                    onChange={(e) => this.onGswpstkValueChange(e.target.value)}
                                />
                                {swapType === 2 &&
                                    <div style={{ alignSelf: 'flex-end', marginLeft: '2rem' }}>
                                        <Button className="btn btn-simple btn-sm btn-flame" onClick={() => this.onGswpstkMaxClick()}>MAX</Button>
                                    </div>
                                }
                            </div>

                        </Col>
                        <Col sm="12" lg="3" style={{ margin: 'auto', textAlign: 'end', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                            <h3 style={{ fontSize: '20px', marginLeft: '2rem', alignSelf: 'flex-end', marginTop: '20px', marginBottom: 0, textAlign: 'end' }}>GSWPSTK</h3>
                        </Col>
                    </Row>

                    {gswpstkError && <Badge color="danger" className="ml-1">{gswpstkError}</Badge>}
                </div>
            </div>
        )
    }

    render() {
        const { swapType, gswpstkAmount, gswpAmount, gswpError, gswpstkError } = this.state;
        const {
            gswpstkBalance,
            gswpBalance,
            gasPrice,
            exchangeRate,
        } = this.props;

        return (
            <Card className="card-swap" style={{ width: '100%' }}>
                <CardHeader>
                    <CardTitle style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Row style={{ width: '100%' }}>
                            <Col sm="12" md="4">
                                <div className="icon-big icon-flame text-center text-flame" style={{ display: 'flex' }}>
                                    <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                                        <h1 className="text-flame text-head"><i className="fa fa-handshake text-flame" style={{ marginRight: '12px' }} />Swap</h1>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Nav
                                    style={{ display: 'flex', borderBottom: 'none' }}
                                    className="nav-tabs-flame ml-auto mb-4"
                                    role="tablist"
                                    tabs
                                >
                                    <NavItem style={{ maxWidth: '30vw' }}>
                                        <NavLink
                                            disabled={swapType === 1}
                                            className={swapType === 1 ? "active" : ""}
                                            onClick={() => this.toggleSwapType()}
                                        >Stake
                                    </NavLink>
                                    </NavItem>
                                    <NavItem style={{ maxWidth: '30vw' }}>
                                        <NavLink
                                            disabled={swapType === 2}
                                            className={swapType === 2 ? "active" : ""}
                                            onClick={() => this.toggleSwapType()}
                                        >Claim
                                    </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ background: '#2d1a0f', borderRadius: '1rem', margin: '6px', flex: 1 }}>
                            <div className="text-warm" style={{ background: '#2d1a0f', padding: '0.5rem', borderRadius: '1rem', textAlign: 'center' }}>
                                {'GSWP -> GSWPSTK:'}
                                <h3 style={{ marginBottom: 0 }}>{this.props.exchangeRate}</h3>
                            </div>
                        </div>
                        <div style={{ background: '#2d1a0f', borderRadius: '1rem', margin: '6px', width: '100%, flex: 1' }}>
                            <div className="text-warm" style={{ background: '#2d1a0f', padding: '0.5rem', borderRadius: '1rem', textAlign: 'center' }}>
                                GAS PRICE:
                                <h3 style={{ marginBottom: 0 }}>{this.props.gasPrice}</h3>
                            </div>
                        </div>
                        <div style={{ background: '#2d1a0f', borderRadius: '1rem', margin: '6px', flex: 1 }}>
                            <div className="text-warm" style={{ background: '#2d1a0f', padding: '0.5rem', borderRadius: '1rem', textAlign: 'center' }}>
                                {'GSWPSTK -> GSWP:'}
                                <h3 style={{ marginBottom: 0 }}>{this.props.exchangeRate}</h3>
                            </div>
                        </div>
                    </div>

                    <hr style={{ background: 'white', width: '100%' }}></hr>

                    {(parseFloat(gswpBalance) === 0 && swapType === 1) || (parseFloat(gswpstkBalance) === 0 && swapType === 2) ?
                        <div>{this.renderInsufficientBalance()}</div>
                        :
                        <div>{this.renderSwapContent()}</div>
                    }
                    {this.props.swapLoading ?
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Loader
                                type="ThreeDots"
                                color="#fd8f3e"
                                height={50}
                                width={50}
                            />
                        </div>
                        :
                        <div>
                            {parseFloat(gswpstkAmount) > 0 && parseFloat(gswpAmount) > 0 && !gswpError && !gswpstkError &&
                                <Button
                                    onClick={() => this.onSwapClick()}
                                    block
                                    className="btn-simple btn-round btn-flame"
                                    color="white"
                                    size="lg"
                                >
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <i className="fa fa-handshake text-flame" style={{ marginRight: '12px', fontSize: '28px' }} />
                                        <div className="text-hl text-flame">SWAP</div>
                                    </div>
                                </Button>
                            }
                        </div>
                    }
                </CardBody>
            </Card>
        )
    }

}
